<template>
  <div class="navbar-container d-flex content align-items-center">

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-dropdown
        id="choose-project"
        variant="outline-primary"
        aria-role="list"
        style="margin-right: 5px"
        :text="(selectedProject == null? 'Выберите проект...' : selectedProject.name)"
      >
        <b-dropdown-item
          v-for="(project, index) in enableProjects"
          :key="index"
          aria-role="list_item"
          @click="setActiveProject(project)"
        >
          {{ project.name }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          :to="{ path:`/projects/new` }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            style="margin-top: -3px;"
          />
          <span>Создать проект</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownDivider, BDropdownItem, BLink, BNavbarNav,
} from 'bootstrap-vue'
import Vue from 'vue'
import UserDropdown from './components/UserDropdown.vue'
import Bookmarks from './components/Bookmarks.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    Bookmarks,
    NotificationDropdown,
    BDropdownDivider,
    BDropdownItem,
    BDropdown,
    // Navbar Components
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      enableProjects: [],
      selectedProject: null,
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
  },
  created() {
    Vue.prototype.startSignalR()
    console.log(this.enableProjects, 'this.enableProjects')
    this.$http.get('/account/user').then(res => {

    })

    this.$store.dispatch('project/fetchAllUserProjects')
      .then(response => {
        const projects = response.data
        console.log(projects, 'this.enableProjects')
        if (projects != null && projects.length !== 0) {
          this.enableProjects = projects.filter(c => c.isEnabled)

          // Update projects state
          this.$store.commit('project/UPDATE_PROJECTS_LIST', projects)

          this.selectedProject = this.enableProjects
            .find(c => c.id === this.activeProjectId)
        }
      })
  },
  methods: {
    setActiveProject(project) {
      this.$store.commit('project/UPDATE_LOADING_PROJECT', true)
      this.$store.commit('statistic/CLEAR_GRAPH_DATA')
      this.$store.commit('statistic/CLEAR_TABLE_DATA')
      this.$store.commit('statistic/CLEAR_RECOMMENDATION_DATA')
      this.$store.commit('statistic/CLEAR_EFFICIENCY_DATA')
      this.$store.commit('statistic/CLEAR_KPI')
      this.$store.commit('statistic/CLEAR_SETTING_REC')
      this.selectedProject = project
      this.$store.commit('project/UPDATE_ACTIVE_PROJECT_ID', project.id)
    },
  },
}
</script>

<style scoped>
#choose-project /deep/ .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}
</style>
